<template>
  <page-title-component v-if="campaign" :title="campaign.google_campaign_name"></page-title-component>

  <div class="box box-shadow bg-white mb-4 p-3 rounded">
    <div class="row">
      <div class="col-md-3 col-4 mb-md-0 mb-3">
        <div class="input-group mb-md-0 mb-1">
            <input type="text"  id="reportrange" class="form-control" value="10/24/1984">
            <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"><i class="flaticon-calendar"></i></span>
            </div>
        </div>
      </div>

      <div class="col-md-3 col-4 mb-md-0 mb-3">
        <select class="form-select form-control"
                v-model="queryParams.profileId"
                @change="getAmazonCampaigns"
                ref="dashboardAdAccount">
          <option value="">Select Ad Account</option>
          <option :value="account.profileId"
                  v-for="account in amazonAdAccounts"
                  :key="'ac_'+account.profileId">{{ account.accountInfo.name }}</option>
        </select>
      </div>

      <div class="col-md-2 col-4 mb-md-0 mb-3">
        <button class="btn btn-primary"
                v-if="campaign"
                @click="$refs.addGoogleKeyword.show()">
          <i class="fas fa-plus"></i> Add Keyword
        </button>
      </div>

    </div>
  </div>

  <div class="statbox widget box box-shadow">
    <div class="widget-content widget-content-area rounded" >
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table id="table2" class="tablesaw table-striped" data-tablesaw-mode="swipe" data-tablesaw-sortable data-tablesaw-sortable-switch data-tablesaw-minimap data-tablesaw-mode-switch>
                        <thead>
                          <tr>
                            <th>Keyword Text</th>
                            <th>Match Type</th>
                            <th>Status</th>
                            <th class="no-wrap" @click="sortTable('impressions')">
                              Impressions

                              <span v-if="this.table.sortColumn === 'impressions'">
                                <i class="fas fa-sort-amount-down-alt"
                                  aria-hidden="true"
                                  v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                  aria-hidden="true" v-else></i>
                              </span>
                            </th>
                            <th class="no-wrap" @click="sortTable('clicks')">
                              Clicks
                              <span v-if="this.table.sortColumn === 'clicks'">
                                <i class="fas fa-sort-amount-down-alt"
                                  aria-hidden="true"
                                  v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                  aria-hidden="true" v-else></i>
                              </span>
                            </th>
                            <th class="no-wrap" @click="sortTable('cost')">
                              Cost
                              <span v-if="this.table.sortColumn === 'cost'">
                                <i class="fas fa-sort-amount-down-alt"
                                  aria-hidden="true"
                                  v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                  aria-hidden="true" v-else></i>
                              </span>
                            </th>
                            <th class="no-wrap" @click="sortTable('cpc')">
                              Average CPC
                              <span v-if="this.table.sortColumn === 'cpc'">
                                <i class="fas fa-sort-amount-down-alt"
                                  aria-hidden="true"
                                  v-if="this.table.sortOrder === 'asc'"></i>

                                <i class="fas fa-sort-amount-down"
                                  aria-hidden="true" v-else></i>
                              </span>
                            </th>
                            <th>Add To Carts</th>
                            <th>Conversions</th>
                            <th>Revenue</th>
                            <th>AMZ Organic Rank</th>
                            <th>AMZ Search Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="keyword in sortedKeywords" :key="'keyword_'+keyword.id">
                            <td>
                              <span v-if="keyword.match_type === 2">[{{ keyword.text }}]</span>
                              <span v-else-if="keyword.match_type === 3">"{{ keyword.text }}"</span>
                              <span v-else>{{ keyword.text }}</span>
                            </td>
                            <td>
                              <span v-if="keyword.match_type === 2">Exact</span>
                              <span v-else-if="keyword.match_type === 3">Phrase</span>
                              <span v-else>Broad</span>
                            </td>
                            <td>
                              <div class="btn-group m-h-34" v-if="keyword.status.status">
                                <button type="button" class="btn btn-sm w-80" :class="['btn-' + keyword.status.color]">{{ keyword.status.status }} <i class="mdi mdi-chevron-down"></i></button>
                                <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" :class="['btn-' + keyword.status.color]" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                                  <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                                  <a class="dropdown-item"
                                  @click.prevent="changeKeywordStatus(2, keyword)"
                                  href="#" >Enable</a>
                                  <div class="dropdown-divider"></div>
                                  <a class="dropdown-item"
                                    @click.prevent="changeKeywordStatus(3, keyword)"
                                    href="#">Pause</a>
                                </div>
                              </div>
                            </td>
                            <td>{{ keyword.impressions }}</td>
                            <td>{{ keyword.clicks }}</td>
                            <td>${{ keyword.cost.toFixed(2) }}</td>
                            <td>${{ keyword.cpc.toFixed(2) }}</td>
                            <td>
                              <div class="spinner-border spinner-border-sm"
                                  v-if="loadingMetrics"
                                  role="status"></div>
                              <span v-else-if="amazonCampaigns.find(c => c.name && (c.name + '').includes(keyword.id))">
                                {{ amazonCampaigns.filter(c => c.name && (c.name + '').includes(keyword.id)).reduce((a, b) => a + b.atc, 0) }}
                              </span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <div class="spinner-border spinner-border-sm"
                                  v-if="loadingMetrics"
                                  role="status"></div>
                              <span v-else-if="amazonCampaigns.find(c => c.name && (c.name + '').includes(keyword.id))">
                                {{ amazonCampaigns.filter(c => c.name && (c.name + '').includes(keyword.id)).reduce((a, b) => a + b.total_purchase, 0) }}
                              </span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <div class="spinner-border spinner-border-sm"
                                  v-if="loadingMetrics"
                                  role="status"></div>
                              <span v-else-if="amazonCampaigns.find(c => c.name && (c.name + '').includes(keyword.id))">
                                ${{ amazonCampaigns.filter(c => c.name && (c.name + '').includes(keyword.id)).reduce((a, b) => a + b.total_sale, 0).toFixed(2) }}
                              </span>
                              <span v-else>$0.00</span>
                            </td>
                            <td>Coming Soon</td>
                            <td>Coming Soon</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>

  <add-google-keyword v-if="campaign"
                      @added="added"
                      :campaign="campaign"
                      ref="addGoogleKeyword" 
  />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import AddGoogleKeyword from "@/views/keywords/modals/AddGoogleKeyword";
import {mapGetters} from "vuex";

export default {
  components: {PageTitleComponent, AddGoogleKeyword},
  data() {
    return {
      amazonCampaigns: [],
      loadingMetrics: false,
      table: {
        sortColumn: 'impressions',
        sortOrder: 'desc',
      },
      campaign: null,
      keywords: [],
      queryParams: {
        profileId: this.$route.params.profileId,
        groupBy: "CREATIVE",
        startDate: window.moment().subtract(29, "days").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
    sortedKeywords() {
      let c = [...this.keywords];
      if (this.table.sortOrder === 'asc')
        return c.sort((a,b) => a[this.table.sortColumn] - b[this.table.sortColumn]);
      else
        return c.sort((a,b) => b[this.table.sortColumn] - a[this.table.sortColumn]);
    },
  },
  created() {
    this.getCampaignDetails();
    this.getKeywords();
    this.getAmazonCampaigns();
  },
  mounted() {
    this.initDateRange();
  },
  methods: {
    added() {
      this.getKeywords();
    },
    sortTable(col) {
      let order = 'asc';

      if (col === this.table.sortColumn) {
        if (this.table.sortOrder === 'asc')
          order = 'desc';
      }

      this.table.sortColumn = col;
      this.table.sortOrder = order;
    },
    initDateRange() {
      let t = window.moment().subtract(29, "days");
      let a = window.moment();
      let self = this;

      window.$("#reportrange").daterangepicker({
          startDate: t,
          endDate: a,
          locale: {
            format: 'MMM D, YYYY'
          },
          ranges: {
            Today: [window.moment(), window.moment()],
            Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
            "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
            "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
            "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
            "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
            "All Time": [window.moment().subtract(15, "years").startOf("month"), window.moment()],
          },
        },
        function (t, a) {
          self.queryParams.startDate = t.format('YYYYMMDD');
          self.queryParams.endDate = a.format('YYYYMMDD');
          self.getKeywords();
          self.getAmazonCampaigns();
        }
      )
    },
    changeKeywordStatus(status, keyword) {
      this.axios.post('/google/keyword/change-status', {
        status: status,
        id: keyword.id,
        customerId: keyword.customer_id,
        adGroupId: keyword.ad_group_id
      })

      setTimeout(() => {
        keyword.status = this.getGoogleCampaignStatus(status);
      }, 100)
    },
    getCampaignDetails() {
      this.axios.get('/google/campaign/' + this.$route.params.id)
        .then(res => this.campaign = res.data.data)
    },
    getKeywords() {
      this.keywords = [];
      this.axios.post('/google/keywords', {
          campaignId: this.$route.params.id,
          startDate: this.queryParams.startDate,
          endDate: this.queryParams.endDate,
        })
        .then(res => {
          this.keywords = res.data
          this.keywords.forEach(keyword => {
            keyword.status = this.getGoogleCampaignStatus(keyword.status)
          })
        })
    },
    getAmazonCampaigns() {
      this.loadingMetrics = true;
      this.amazonCampaigns = [];

      this.axios.get('/ads/amazon/campaign', {
          params: this.queryParams
        })
        .then(res => {
          this.amazonCampaigns = res.data.data
        })
        .finally(() => this.loadingMetrics = false)
    }
  }
}
</script>